@import "src/sass/utils";

.AppFooter {
  position: relative;
  padding: 120px 48px 100px;
  margin-top: 24px;
  background-color: var(--footer-bg-color);
  background-repeat: repeat-x;
  color: var(--footer-link-color);

  @include phone {
    padding: 60px 16px 64px;
  }

  &__PoweredBy {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    font-size: 0.74rem;

    a {
      &:hover,
      &.hover {
        color: var(--primary-color);
      }
    }

    @include phone {
      right: 0;
      justify-content: center;
      align-self: center;
      font-size: 1.5rem;
      text-align: center;
    }

    @include tablet {
      font-size: 1.25rem;
    }
  }

  &__Line {
    display: block;
    height: 0px;
    border: solid 1px #2e2e2e;
    margin-bottom: 5rem;
  }

  &__Content {
    @include prevent-tab-highlights;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;

    .Row {
      display: flex;
      align-items: center;
      gap: 25px;
      font-size: 1rem;
      letter-spacing: 0.6px;
      min-height: 32px;
      flex-wrap: wrap;

      @include phone {
        gap: 15px;
      }

      &__Justify {
        justify-content: center;
      }

      &__FitToContent {
        justify-content: space-around;
      }
    }

    .Column {
      display: flex;
      align-items: center;

      .Icon {
        max-height: 30px;
        margin: 0 8px;

        @include tabletDown {
          margin: 0;
        }
      }
    }
  }
}
